import React from "react";
import Navbar from "./components/navbar";
import "../src/about.css";
import ProLoader from "./components/preloader";
import teamPhoto from "../src/img/team.jpg"
import "../src/team.css"

function Team() {
  return (
    <>
      <Navbar />
      <section className="hero">
        <div className="landing">
          <div className="land-left">
            <img src={teamPhoto} alt="team" className="team-photo"></img>
            {/* <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam
                voluptatum, quibusdam, quia, quos voluptates voluptatem quod
                consequatur voluptate quas quidem doloribus. Quisquam voluptatum,
                quibusdam, quia, quos voluptates voluptatem quod consequatur
            </p> */}
          </div>
          <div className="land-right"></div>
        </div>
        <br></br>
        <br></br>

        <br></br>

        <br></br>

        <br></br>

        <br></br>
        <br></br>

        <br></br>

        <br></br>

        <br></br>
        <br></br>
        <br></br>
      </section>
      <ProLoader />
    </>
  );
}

export default Team;
