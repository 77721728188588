import logo from "./logo.svg";
import "./App.css";
// import helmet from "./helmet.png";
import arrow from "../src/img/arrow.svg";
import { useEffect } from "react";
// import preLoad from "https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/f3e01c03-d24b-459d-a759-721a87a48946/de4jh0n-d13024e4-ea29-46d8-b7c9-e3fd1e0c45c6.gif?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcL2YzZTAxYzAzLWQyNGItNDU5ZC1hNzU5LTcyMWE4N2E0ODk0NlwvZGU0amgwbi1kMTMwMjRlNC1lYTI5LTQ2ZDgtYjdjOS1lM2ZkMWUwYzQ1YzYuZ2lmIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.HZ5rnBis1b9ClJgcK7xBzDVcJ8hgwBpBj2MuAISMlqE"
import Navbar from "../src/components/navbar.js";
import PreLoader from "../src/components/preloader.js";

function Home() {
  return (
    <div className="App">
      <Navbar></Navbar>
      <section className="hero">
        <div className="landing">
          <div className="land-left">
            <h1 className="Interact-club">
              Interact Club of <br />
              Richmond College
            </h1>
            <p className="description">
              Over the years, the Interact Club of Richmond College has been an
              active participant of those who work for the betterment of the
              community; to uplift the wellbeing of the people, to improve the
              effectiveness of services, to protect mother nature, to raise
              awareness about many crucial matters and many more. And we also
              hope to continue the tradition for many more years to come, to
              create a never-ending legacy; a legacy of greatness.
            </p>
            <a href="/about">
              <button className="btn">
                Learn More <img className="arrow" src={arrow}></img>
              </button>
            </a>
          </div>
          <div className="land-right"></div>
        </div>
        <br></br>
        <br></br>

        <br></br>

        <br></br>

        <br></br>

        <br></br>
        <br></br>

        <br></br>

        <br></br>

        <br></br>
        <br></br>
        <br></br>
      </section>
      <PreLoader></PreLoader>
    </div>
  );
}

export default Home;
