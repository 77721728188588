import React from "react";
import Home from "./home.js";
import Projects from "./projects.js";
import About from "./about.js";
import Team from "./team.js";
import Gallery from "./gallery.js";
import Contact from "./contact.js"
// import News from "./Pages/news";
// import Registration from "./Pages/register";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import Contact from "./Pages/contact";


function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/about" element={<About />} />
          <Route path="/team" element={<Team />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/contact" element={<Contact />} />
          {/* <Route path="/about" element={<About />} /> */}
          {/* <Route path="/registration" element={<Registration />} /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;