import React from "react";
import intlogo from "../img/logo.png";
import "../components/navbar.css";
import { useState } from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";

function Navbar() {
  const [clicked, setClicked] = useState(" ");
  const handleClicked = () => setClicked("display");
  const handleClosed = () => setClicked(" ");

  return (
    <>
      <nav >
        <ul>
          <CustomLink to="/">Home</CustomLink>
          <CustomLink to="/projects">Projects</CustomLink>
          <CustomLink to="/about">About</CustomLink>
          <CustomLink to="/team">Our Team</CustomLink>
          <CustomLink to="/gallery">Gallery</CustomLink>
        </ul>
      </nav>
      <div className="cont-wrap">
        <a class="icon" onClick={handleClicked}>
          <i class="fa fa-bars"></i>
        </a>
        <a href="">
          <button className="contact-btn">Contact us</button>
        </a>
      </div>
      <div>
        <a href="/contact">
          <button className="logos-wrap">
            <img src={intlogo}></img>
          </button>
        </a>
      </div>
      <div className={"topnav , " + clicked} id="myTopnav" onClick={handleClosed}>
        <ul>
          <CustomLink2 to="/">Home</CustomLink2>
          <CustomLink2 to="/projects">Projects</CustomLink2>
          <CustomLink2 to="/about">About</CustomLink2>
          <CustomLink2 to="/team">Our Team</CustomLink2>
          <CustomLink2 to="/gallery">Gallery</CustomLink2>
        </ul>
      </div>
    </>
  );
}

function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });

  return (
    <li
      className={isActive ? "active , desktop-nav" : "desktop-nav"}
    >
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  );
}
function CustomLink2({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });

  return (
    <li
      className={isActive ? "active , mobile-nav" : "mobile-nav"}
    >
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  );
}



export default Navbar;
