import React from "react";
import Navbar from "./components/navbar";
import "../src/about.css";
import ProLoader from "./components/preloader";

function About() {
  return (
    <>
      <Navbar />
      <section className="hero">
        <div className="landing">
          <div className="land-left">
            <h1 className="Interact-club">
              Interact Club of <br />
              Richmond College
            </h1>
            <p className="description">
              Over the years, the Interact Club of Richmond College has been an
              active participant of those who work for the betterment of the
              community; to uplift the wellbeing of the people, to improve the
              effectiveness of services, to protect mother nature, to raise
              awareness about many crucial matters and many more. And we also
              hope to continue the tradition for many more years to come, to
              create a never-ending legacy; a legacy of greatness.
            </p>
          </div>
          <div className="land-right"></div>
        </div>
        <br></br>
        <br></br>

        <br></br>

        <br></br>

        <br></br>

        <br></br>
        <br></br>

        <br></br>

        <br></br>

        <br></br>
        <br></br>
        <br></br>
      </section>
      <ProLoader />
    </>
  );
}

export default About;
