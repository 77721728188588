import React from "react";
import Navbar from "./components/navbar";
import "../src/projects.css";
import ProLoader from "./components/preloader";

function Projects() {
  return (
    <>
      <Navbar />
      <section className="projects-hero">
        <div className="projects-landing">
          <h1 className="projects-title">Key Projects!!!</h1>
        </div>
      </section>
      <section className="projects">
        <div className="projects-wrap">
          <div className="projects-card">
            <div className="projects-card-img">
              <img src="https://scontent.fcmb2-2.fna.fbcdn.net/v/t39.30808-6/367483673_621048646832505_7993822133400424295_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=49d041&_nc_ohc=65KMWXdXDL8AX9PWxhv&_nc_ht=scontent.fcmb2-2.fna&oh=00_AfAZ0JLXvhXKl9P7ECLvcPaDHQUM0JY_ZjGyIp6HRYQagw&oe=6504ED03"></img>
            </div>
            <div className="projects-card-info">
              <h2 className="projects-card-title">Project 1</h2>
              <p className="projects-card-desc">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptatum.</p>
               
            </div>
          </div>
          <div className="projects-card">
            <div className="projects-card-img">
              <img src="https://scontent.fcmb2-2.fna.fbcdn.net/v/t39.30808-6/325937494_853753275730751_4144222940838946444_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=5614bc&_nc_ohc=3Oz4yfSCptAAX_Ze4eR&_nc_ht=scontent.fcmb2-2.fna&oh=00_AfAMJpEPp2mBvEXe1cUM4EF-m8feL0LLR19PLoa-W_4QsA&oe=65052C2C"></img>
            </div>
            <div className="projects-card-info">
              <h2 className="projects-card-title">Project 1</h2>
              <p className="projects-card-desc">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptatum.</p>
               
            </div>
          </div>
          <div className="projects-card">
            <div className="projects-card-img">
            <img src="https://scontent.fcmb2-2.fna.fbcdn.net/v/t39.30808-6/310453731_426852316252140_6278923721928727850_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=5614bc&_nc_ohc=7CJhwmD3Jv8AX9cPi1s&_nc_oc=AQlg6yDrK3e9vvrVVIvtfvO1-XRKHaGyF4emdTcYap3HKRHR_1Sq1_MFpAO8nguRcoM&_nc_ht=scontent.fcmb2-2.fna&oh=00_AfAKjw234YVNvCzS51RXOBIqh-yWmBZugwEoJ0Xs3oz5_A&oe=6505FF7A"></img>

            </div>
            <div className="projects-card-info">
              <h2 className="projects-card-title">Project 1</h2>
              <p className="projects-card-desc">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptatum.</p>
               
            </div>
          </div>
          <div className="projects-card">
            <div className="projects-card-img">
            <img src="https://scontent.fcmb2-2.fna.fbcdn.net/v/t39.30808-6/310800959_426850799585625_3448951609966872954_n.jpg?_nc_cat=104&ccb=1-7&_nc_sid=5614bc&_nc_ohc=WezrF0uFQyQAX8NPmGc&_nc_ht=scontent.fcmb2-2.fna&oh=00_AfB1ousw52VPvKORehUdfRZRdUwmHg_ZClbLeUoA680mPQ&oe=65055798"></img>

            </div>
            <div className="projects-card-info">
              <h2 className="projects-card-title">Project 1</h2>
              <p className="projects-card-desc">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptatum.</p>
               
            </div>
          </div>
          </div>
      </section>
      <ProLoader />
    </>
  );
}

export default Projects;
