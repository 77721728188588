import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';


import Projects from "./projects.js";
// import App from "./App.js";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <><React.StrictMode>
    <App />
  </React.StrictMode>
  {/* <Routes>
    <Route path="/projects" element={<Projects />} />
  </Routes> */}
  </>
);

