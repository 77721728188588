import React from "react";
import preLoad from "../img/preload.gif";
import { useEffect } from "react";
import "../components/preloader.css";

function PreLoader() {
  useEffect(() => {
    setTimeout(() => {
      document.querySelector(".preloader").style.display = "none";
    }, 3000);
  });
  return (
    <section className="preloader">
      <div className="preloader-wrap">
        <div className="preloader-logo">
          {/* <img src="https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/ad579fa0-423d-4a61-a337-5155f8e89cdb/deaqtyv-22cac72e-5602-4655-9fd4-2b2db6b538c0.gif?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcL2FkNTc5ZmEwLTQyM2QtNGE2MS1hMzM3LTUxNTVmOGU4OWNkYlwvZGVhcXR5di0yMmNhYzcyZS01NjAyLTQ2NTUtOWZkNC0yYjJkYjZiNTM4YzAuZ2lmIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.m2yocBe7rjPjEMJaLgXYgpBuFa8-T52Ujzqf0Xpob40"></img> */}
          <img src={preLoad}></img>
        </div>
      </div>
    </section>
  );
}

export default PreLoader;
