import React from "react";
import Navbar from "./components/navbar";
import "../src/projects.css";
import ProLoader from "./components/preloader";

function Gallery() {
  return (
    <>
      <Navbar />
      <section className="projects-hero">
        <div className="projects-landing">
          <h1 className="projects-title">Gallery</h1>
        </div>
      </section>
      <section className="projects">
        <div className="projects-wrap">
          <div className="projects-card">
            <div className="projects-card-img">
              <img src="https://scontent.fcmb2-2.fna.fbcdn.net/v/t39.30808-6/367483673_621048646832505_7993822133400424295_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=49d041&_nc_ohc=65KMWXdXDL8AX9PWxhv&_nc_ht=scontent.fcmb2-2.fna&oh=00_AfAZ0JLXvhXKl9P7ECLvcPaDHQUM0JY_ZjGyIp6HRYQagw&oe=6504ED03"></img>
            </div>
          </div>
          <div className="projects-card">
            <div className="projects-card-img">
              <img src="https://scontent.fcmb2-2.fna.fbcdn.net/v/t39.30808-6/367483673_621048646832505_7993822133400424295_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=49d041&_nc_ohc=65KMWXdXDL8AX9PWxhv&_nc_ht=scontent.fcmb2-2.fna&oh=00_AfAZ0JLXvhXKl9P7ECLvcPaDHQUM0JY_ZjGyIp6HRYQagw&oe=6504ED03"></img>
            </div>
          </div>
          <div className="projects-card">
            <div className="projects-card-img">
              <img src="https://scontent.fcmb2-2.fna.fbcdn.net/v/t39.30808-6/367483673_621048646832505_7993822133400424295_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=49d041&_nc_ohc=65KMWXdXDL8AX9PWxhv&_nc_ht=scontent.fcmb2-2.fna&oh=00_AfAZ0JLXvhXKl9P7ECLvcPaDHQUM0JY_ZjGyIp6HRYQagw&oe=6504ED03"></img>
            </div>
          </div>
          <div className="projects-card">
            <div className="projects-card-img">
              <img src="https://scontent.fcmb2-2.fna.fbcdn.net/v/t39.30808-6/367483673_621048646832505_7993822133400424295_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=49d041&_nc_ohc=65KMWXdXDL8AX9PWxhv&_nc_ht=scontent.fcmb2-2.fna&oh=00_AfAZ0JLXvhXKl9P7ECLvcPaDHQUM0JY_ZjGyIp6HRYQagw&oe=6504ED03"></img>
            </div>
          </div>
          </div>
          <div className="projects-wrap">
          <div className="projects-card">
            <div className="projects-card-img">
              <img src="https://scontent.fcmb2-2.fna.fbcdn.net/v/t39.30808-6/367483673_621048646832505_7993822133400424295_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=49d041&_nc_ohc=65KMWXdXDL8AX9PWxhv&_nc_ht=scontent.fcmb2-2.fna&oh=00_AfAZ0JLXvhXKl9P7ECLvcPaDHQUM0JY_ZjGyIp6HRYQagw&oe=6504ED03"></img>
            </div>
          </div>
          <div className="projects-card">
            <div className="projects-card-img">
              <img src="https://scontent.fcmb2-2.fna.fbcdn.net/v/t39.30808-6/367483673_621048646832505_7993822133400424295_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=49d041&_nc_ohc=65KMWXdXDL8AX9PWxhv&_nc_ht=scontent.fcmb2-2.fna&oh=00_AfAZ0JLXvhXKl9P7ECLvcPaDHQUM0JY_ZjGyIp6HRYQagw&oe=6504ED03"></img>
            </div>
          </div>
          <div className="projects-card">
            <div className="projects-card-img">
              <img src="https://scontent.fcmb2-2.fna.fbcdn.net/v/t39.30808-6/367483673_621048646832505_7993822133400424295_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=49d041&_nc_ohc=65KMWXdXDL8AX9PWxhv&_nc_ht=scontent.fcmb2-2.fna&oh=00_AfAZ0JLXvhXKl9P7ECLvcPaDHQUM0JY_ZjGyIp6HRYQagw&oe=6504ED03"></img>
            </div>
          </div>
          <div className="projects-card">
            <div className="projects-card-img">
              <img src="https://scontent.fcmb2-2.fna.fbcdn.net/v/t39.30808-6/367483673_621048646832505_7993822133400424295_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=49d041&_nc_ohc=65KMWXdXDL8AX9PWxhv&_nc_ht=scontent.fcmb2-2.fna&oh=00_AfAZ0JLXvhXKl9P7ECLvcPaDHQUM0JY_ZjGyIp6HRYQagw&oe=6504ED03"></img>
            </div>
          </div>
          </div>
          <div className="projects-wrap">
          <div className="projects-card">
            <div className="projects-card-img">
              <img src="https://scontent.fcmb2-2.fna.fbcdn.net/v/t39.30808-6/367483673_621048646832505_7993822133400424295_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=49d041&_nc_ohc=65KMWXdXDL8AX9PWxhv&_nc_ht=scontent.fcmb2-2.fna&oh=00_AfAZ0JLXvhXKl9P7ECLvcPaDHQUM0JY_ZjGyIp6HRYQagw&oe=6504ED03"></img>
            </div>
          </div>
          <div className="projects-card">
            <div className="projects-card-img">
              <img src="https://scontent.fcmb2-2.fna.fbcdn.net/v/t39.30808-6/367483673_621048646832505_7993822133400424295_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=49d041&_nc_ohc=65KMWXdXDL8AX9PWxhv&_nc_ht=scontent.fcmb2-2.fna&oh=00_AfAZ0JLXvhXKl9P7ECLvcPaDHQUM0JY_ZjGyIp6HRYQagw&oe=6504ED03"></img>
            </div>
          </div>
          <div className="projects-card">
            <div className="projects-card-img">
              <img src="https://scontent.fcmb2-2.fna.fbcdn.net/v/t39.30808-6/367483673_621048646832505_7993822133400424295_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=49d041&_nc_ohc=65KMWXdXDL8AX9PWxhv&_nc_ht=scontent.fcmb2-2.fna&oh=00_AfAZ0JLXvhXKl9P7ECLvcPaDHQUM0JY_ZjGyIp6HRYQagw&oe=6504ED03"></img>
            </div>
          </div>
          <div className="projects-card">
            <div className="projects-card-img">
              <img src="https://scontent.fcmb2-2.fna.fbcdn.net/v/t39.30808-6/367483673_621048646832505_7993822133400424295_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=49d041&_nc_ohc=65KMWXdXDL8AX9PWxhv&_nc_ht=scontent.fcmb2-2.fna&oh=00_AfAZ0JLXvhXKl9P7ECLvcPaDHQUM0JY_ZjGyIp6HRYQagw&oe=6504ED03"></img>
            </div>
          </div>
          </div>
      </section>
      <ProLoader />
    </>
  );
}

export default Gallery;
